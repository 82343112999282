import {
  MetaContentFragment,
  MetaContentHead,
  MetaContentProps,
} from '@/components/common/MetaContentHead'
import { Layout } from '@/components/Layout'
import PageBlockRenderer from '@/components/pageBlocks/PageBlockRenderer'
import { fetchMenuCategories, fetchPageComponents } from '@/utils/Contentful'
import { fetchContent } from '@/utils/FetchContent'
import React from 'react'
import {
  TemplateJoinUsCollection,
  TemplateJoinUsPageComponentsCollection,
} from 'types/generated/contentful-types'
import minify from 'gqlmin'
import { logToSentryWithLocalScope } from '@/utils/SentryLogger'
import { MenuItem } from '@/components/common/Types'

type JoinUsProps = {
  page: {
    title: string
    metaContent: MetaContentProps
    showHeader: boolean
    collapseHeader: boolean
    hideGlobalBanner: boolean
  }
  components: TemplateJoinUsPageComponentsCollection
  isPreview: boolean
  menuLinks: MenuItem[]
}

const JoinUs = ({
  page,
  components,
  isPreview,
  menuLinks,
}: JoinUsProps): JSX.Element => {
  const jsonLd = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    name: 'Your dream job, in travel.',
    url: `${process.env.NEXT_PUBLIC_BASE_URL}/join-us`,
  }

  return (
    <>
      <Layout
        showHeader={page.showHeader}
        showPreviewBanner={isPreview}
        collapseHeaderMargin={page.collapseHeader}
        hideGlobalBanner={page.hideGlobalBanner}
        menuLinks={menuLinks}
      >
        <MetaContentHead {...page.metaContent} jsonLd={jsonLd} />
        <PageBlockRenderer {...components} />
      </Layout>
    </>
  )
}

export const getStaticProps = async ({ preview = false }) => {
  try {
    const response = await fetchContent<{
      templateJoinUsCollection: TemplateJoinUsCollection
    }>(
      minify(`
      {
        templateJoinUsCollection(limit: 1, preview: ${preview}) {
          items {
            title
            ${MetaContentFragment}
            showHeader
            collapseHeader
            hideGlobalBanner
          }
        }
      }
    `),
      preview
    )

    // Handle service outage or unsuccessful fetch to serve cached page
    if (!response) {
      return undefined
    }

    const components = await fetchPageComponents(
      'templateJoinUsCollection',
      preview
    )

    const page = response?.templateJoinUsCollection?.items?.[0]

    if (!page) {
      return { notFound: true }
    }

    return {
      props: {
        page,
        components,
        isPreview: preview,
        menuLinks: await fetchMenuCategories(),
      },
    }
  } catch (error) {
    console.error(error)
    logToSentryWithLocalScope(
      error,
      {
        tags: { service: 'Page Build' },
        level: 'critical',
        extra: {},
      },
      'Failed to build Join Us page'
    )
  }

  return undefined
}

export default JoinUs
